<template>
  <div class="about">
    <div style="background: no-repeat center center / cover" :style="{ backgroundImage: 'url(' + require('@/assets/breadcumb.jpg') + ')' }" class="breadcumb-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="breadcumb-title">{{ page.name }} </h2>
            <ul class="breadcumb-menu">
              <li>
                <router-link to="/">{{ langcontent.home }}</router-link>
              </li>
              <li>{{ page.name }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="world-dentistry mb-5 mt--100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="wd-content">
              <div class="section-title style-two">
                <h2>{{ page.name }}</h2>
              </div>
              <div v-html="page.desc"></div>
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>


<script>
import axiosCustom from "../../axiosCustom";
export default {
  data(){
    return{
      page:[],
      langcontent:[],
    }
  },
  created() {
    axiosCustom.get('langfix')
        .then(response => {
          this.langcontent = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
        axiosCustom.get('pages/' + this.$route.params.page)
        .then(response => {
          this.page = response.data
        })
        .catch(error => {
          console.log(error);
        })
   
  },

}
</script>

<style>
.detailTable{
  display: block;
  position: relative;
  width: 100%;
  margin-top: 30px;
}
.detailTable .tableHeader{
  font-size:25px;
  font-weight: bold;
  display: block;
  text-align: center;
  margin-bottom: 15px;
}
.detailTable .tableDesc{
  margin-top: 15px;
  font-size: 80%;
  display: block;
  line-height: 1.1;
  font-style: italic;
}
.detailTable table{
  display: block;
  width: 100%;
  border: 1px white;
 }
.detailTable table thead{
  background-color: rgba(255, 255, 255, 0.2);
  display: block;
  width: 100%;
}
.detailTable table thead tr th{
  border: none;
  text-transform: uppercase;

}
.detailTable table tbody{

  display: block;
  width: 100%;
}
.detailTable table tr{
  width: 100%;
  display: flex;
}
.detailTable table tr th,
.detailTable table tr td{
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 8px;
  flex: 1;
  width: 100%;
}
.detailTable table tr:nth-child(even){
  background-color: rgba(255, 255, 255, 0.2);
}
.detailTable table tr:nth-child(even) td{
  border-top:none;
  border-bottom:none
}

</style>